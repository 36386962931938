<template>
  <div class="anggota">
    <h1 class="mb-5">Data Anggota</h1>

    <!-- Form Pencarian -->
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card">
          <h5 class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            ">
            Form Pencarian
          </h5>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-md-3">
                  <label for="no_kta" class="form-label">No. KTA</label>
                  <input type="text" class="form-control" aria-describedby="button-addon2" v-model="search.no_kta"
                    @keypress.enter="searchData" />
                </div>
                <div class="col-md-3">
                  <label for="nama" class="form-label">Nama</label>
                  <input type="text" class="form-control" aria-describedby="button-addon2" v-model="search.nama"
                    @keypress.enter="searchData" />
                </div>
                <div class="col-md-3">
                  <label for="email" class="form-label">Email</label>
                  <input type="email" class="form-control" aria-describedby="button-addon2" v-model="search.email"
                    @keypress.enter="searchData" />
                </div>
                <div class="col-md-3">
                  <label for="no_identitas" class="form-label">No. Identitas</label>
                  <input type="text" class="form-control" aria-describedby="button-addon2" v-model="search.no_identitas"
                    @keypress.enter="searchData" />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3" v-if="currentUser.role === 'Super User'">
                  <label for="status" class="form-label">Provinsi</label>
                  <v-select v-model="search.provinsi_id" :options="provinsi" :reduce="(provinsi) => provinsi.id"
                    label="nama" @input="onProvinceChange">
                  </v-select>
                </div>
                <div class="col-md-3" v-if="
                  currentUser.role === 'Super User' ||
                  currentUser.role === 'Admin'
                ">
                  <label for="status" class="form-label">Kota/Kab.</label>
                  <v-select v-model="search.kota_id" :options="kota" :reduce="(kota) => kota.id" label="nama"
                    @input="onKotaChange">
                  </v-select>
                </div>
                <div class="col-md-3" v-if="
                  currentUser.role === 'Super User' ||
                  currentUser.role === 'Admin' ||
                  currentUser.role === 'DPC'
                ">
                  <label for="status" class="form-label">Kecamatan</label>
                  <v-select v-model="search.kecamatan_id" :options="kecamatan" :reduce="(kecamatan) => kecamatan.id"
                    label="nama" @input="onKecamatanChange">
                  </v-select>
                </div>
                <div class="col-md-3">
                  <label for="status" class="form-label">Kelurahan</label>
                  <v-select v-model="search.kelurahan_id" :options="kelurahan" :reduce="(kelurahan) => kelurahan.id"
                    label="nama">
                  </v-select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <label class="form-label">Tanggal Registrasi <small>(dari-sampai)</small></label>
                  <v-date-picker v-model="search.createdAt" is-range>
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="d-flex bd-highlight">
                        <input :value="inputValue.start" v-on="inputEvents.start" class="
                            form-control
                            flex-fill
                            bd-highlight
                            rounded
                            focus:outline-none focus:border-indigo-300
                          " />
                        <input :value="inputValue.end" v-on="inputEvents.end" class="
                            form-control
                            flex-fill
                            bd-highlight
                            rounded
                            focus:outline-none focus:border-indigo-300
                          " />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
                <div class="col-md-3">
                  <label for="status" class="form-label">Jenis KTA</label>
                  <v-select v-model="search.jenis_atribut" :options="jenis_kta" label="nama">
                  </v-select>
                </div>
                <div class="col-md-3">
                  <label for="status" class="form-label">Pekerjaan</label>
                  <v-select v-model="search.pekerjaan_id" :options="pekerjaan" :reduce="(pekerjaan) => pekerjaan.id"
                    label="nama">
                  </v-select>
                </div>
                <div class="col-md-3">
                  <label for="status" class="form-label">Hobi</label>
                  <v-select v-model="search.hobi_id" :options="hobi" :reduce="(hobi) => hobi.id" label="nama">
                  </v-select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <label for="status" class="form-label">Status</label>
                  <select class="form-select" v-model="search.status">
                    <option value="">Pilih...</option>
                    <option v-for="option in status" :value="option.value" :key="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mx-1 mt-3" @click="searchData">
              <i class="bi bi-search"></i>
              Cari data
            </button>
            <button class="btn btn-secondary mx-1 mt-3" @click="resetFilter">
              <i class="bi bi-backspace-reverse"></i>
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Card Tabel Anggota -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <h5 class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            ">
            Daftar Anggota
            <div class="row">
              <router-link :to="{ name: 'admin-anggota-add' }" class="btn btn-primary btn-sm">
                <i class="bi bi-plus-circle"></i>

                Tambah Baru
              </router-link>
              <!-- <router-link
                :to="{ name: 'admin-anggota-import' }"
                class="btn btn-danger btn-sm"
              >
                <i class="bi bi-plus-circle"></i>

                Impor Data
              </router-link> -->
            </div>
          </h5>
          <div class="row py-4 px-4">
            <div class="col-md-12">
              <button class="btn btn-secondary" @click="exportToExcel" :disabled="isExporting">
                <i class="bi bi-file-earmark-arrow-down"></i>
                <span>{{
                  isExporting? "Mengekspor Data": "Export ke Excel"
                }}</span>
              </button>
            </div>
          </div>
          <div class="card-body table-responsive">
            <transition name="fade">
              <table class="table table-hover" v-if="!loadingData">
                <thead>
                  <tr>
                    <th scope="col">No. Urut</th>
                    <th scope="col" @click="sort_by('nomor_kta')" style="cursor: pointer">
                      No. KTA
                    </th>
                    <th scope="col" @click="sort_by('nama_lengkap')" style="cursor: pointer">
                      Nama Lengkap
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Alamat</th>
                    <th scope="col">Kecamatan</th>
                    <th scope="col">Kota</th>
                    <th scope="col">Provinsi</th>
                    <th scope="col">Status</th>
                    <th scope="col" @click="sort_by('createdAt')" style="cursor: pointer">
                      Tanggal Registrasi
                    </th>
                    <!-- <th scope="col">Tanggal Diubah</th> -->
                    <th scope="col">Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!anggota.length">
                    <td colspan="11" class="text-center">
                      <span>Tidak ada data ditemukan.</span>
                    </td>
                  </tr>
                  <tr v-for="(angg, index) in anggota" :key="angg.id">
                    <td>
                      {{
  currentPage + (currentPage - 1) * (limit - 1) + index
                      }}
                    </td>
                    <td>{{ angg.nomor_kta || "-" }}</td>
                    <td>{{ angg.nama_lengkap }}</td>
                    <td>{{ angg.email }}</td>
                    <td width="30%">{{ angg.alamat }}</td>
                    <td>{{ (angg.Kecamatan && angg.Kecamatan.nama) || "" }}</td>
                    <td>{{ angg.Kecamatan.kota.nama }}</td>
                    <td>{{ angg.Kecamatan.kota.propinsi.nama }}</td>
                    <td>
                      <status-badge :status="angg.status_anggota"></status-badge>
                    </td>
                    <!-- <td>{{ angg.updatedAt }}</td> -->
                    <td>{{ localDate(angg.createdAt) }}</td>
                    <td width="10%">
                      <div class="btn-group" role="group" aria-label="Action menu">
                        <button type="button" class="btn btn-success btn-sm" @click="approveAnggota(angg.id)"
                          v-if="angg.status_anggota === 'WAITING APPROVAL' && (currentUser.role === 'Super User' ||currentUser.role === 'PAC')">
                          <i class="bi bi-check"></i> Setujui
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" @click="rejectAnggota(angg.id)"
                          v-if="angg.status_anggota === 'WAITING APPROVAL' && (currentUser.role === 'Super User' ||currentUser.role === 'PAC')">
                          <i class="bi bi-x"></i> Tolak
                        </button>
                        <!-- <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="confirmPayment(angg.id)"
                          v-if="
                            currentUser.role == 'Super User' &&
                            angg.status_anggota === 'WAITING PAYMENT'
                          "
                        >
                          <i class="bi bi-check"></i> Konfirmasi Pembayaran
                        </button>
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="confirmProduction(angg.id)"
                          v-if="
                            currentUser.role == 'Super User' &&
                            angg.status_anggota === 'WAITING PRODUCTION'
                          "
                        >
                          <i class="bi bi-check"></i> Konfirmasi Pencetakan
                        </button> -->
                        <router-link class="btn btn-secondary btn-sm" :to="{
                          name: 'admin-anggota-detail',
                          params: { id: angg.id },
                        }"><i class="bi bi-eye"></i> Lihat Detail</router-link>
                        <button v-if="currentUser.role === 'Super User'" type="button" class="btn btn-secondary btn-sm"
                          @click="showQrCode(angg.qr_code)">
                          <i class="bi bi-upc"></i> Lihat QR
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" @click="confirmDelete(angg.id)"
                        v-if="currentUser.role === 'Super User'">
                          <i class="bi bi-trash"></i> Hapus
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-center">
                <div class="spinner-grow" role="status"></div>
              </div>
            </transition>
            <div class="d-flex justify-content-start">
              <nav v-if="anggota.length" class="me-2">
                <ul class="pagination">
                  <li class="page-item" :class="currentPage === 1 ? 'disabled' : ''">
                    <a class="page-link" href="#" @click="previousPage()">Sebelumnya</a>
                  </li>
                  <li class="page-item" :class="currentPage === totalPage ? 'disabled' : ''">
                    <a class="page-link" href="#" @click="nextPage()">Selanjutnya</a>
                  </li>
                </ul>
                <small>Total data: {{ totalData }}</small> <br />
                <small>Halaman: {{ currentPage }} dari {{ totalPage }}</small>
              </nav>

              <div class="">
                <select class="form-select" v-model="limit" @change="searchData">
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <small>data per halaman</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal konfirmasi hapus -->
    <delete-modal :showModal="showDeleteModal" :id="selectedAnggotaId" @closeModal="closeDeleteModal"
      @destroy="destroyAnggota" />
  </div>
</template>

<script>
import { authenticationService } from "@/services";
import {
  anggotaService,
  provinsiService,
  kotaService,
  kecamatanService,
  kelurahanService,
  pekerjaanService,
  hobiService,
} from "@/services";
import StatusBadge from "./StatusBadge.vue";
import DeleteModal from "./components/DeleteModal.vue";

export default {
  components: {
    StatusBadge,
    DeleteModal,
  },
  data() {
    return {
      anggota: [],
      provinsi: [],
      kota: [],
      kecamatan: [],
      kelurahan: [],
      pekerjaan: [],
      hobi: [],
      status: [
        { label: "Menunggu Persetujuan", value: "WAITING APPROVAL" },
        { label: "Menunggu Pembayaran", value: "WAITING PAYMENT" },
        { label: "Menunggu Pencetakan", value: "WAITING PRODUCTION" },
        { label: "Disetujui", value: "APPROVED" },
        { label: "Ditolak", value: "REJECTED" },
      ],
      search: {
        no_kta: "",
        nama: "",
        email: "",
        no_identitas: "",
        provinsi_id: "",
        kota_id: "",
        kecamatan_id: "",
        kelurahan_id: "",
        status: "",
        jenis_atribut: "",
        pekerjaan_id: "",
        hobi_id: "",
        createdAt: {
          start: "",
          end: "",
        },
      },
      limit: 10,
      loadingData: false,
      totalData: 0,
      currentPage: 1,
      order: "desc",
      sort: "updatedAt",
      currentUser: authenticationService.currentUserValue,
      isApproving: false,
      selectedAnggotaId: 0,
      showDeleteModal: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      jenis_kta: [
        "Plus Kemeja (Rp.200.000)",
        "Plus Kaos (Rp.150.000)",
        "Hanya KTA (Rp.55.000)",
      ],

      columns: [
        {
          label: "Product",
        },
        {
          label: "Price",
        },
        {
          label: "Quantity",
        },
        {
          label: "Country",
        },
        {
          label: "City",
        },
      ],

      isExporting: false,
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    async exportToExcel() {
      this.isExporting = true;
      await anggotaService.exportToExcel(this.search, this.sort, this.order);
      this.isExporting = false;
    },

    localDate(date) {
      return date.substring(0, 10);
    },

    // Data & Filtering methods
    searchData() {
      this.loadingData = true;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    loadData(limit, search, page, sort, order) {
      anggotaService
        .getAll(limit, search, page, sort, order)
        .then((data) => {
          this.anggota = data.rows;
          this.totalData = data.count;
          this.currentPage = page;
        })
        .catch((error) => {
          this.$toast.error(`Gagal memuat data: ${error.message}`);
        });
      this.loadingData = false;
    },
    loadDataLokasi() {
      if (this.currentUser.role === "Super User" || this.currentUser.role === "Admin") {
        provinsiService.getAll(1000, "", 1).then((data) => {
          this.provinsi = data.rows;
        });
      } else {
        kotaService
          .getAll("", "", "", "", "", this.currentUser.propinsi_id)
          .then((data) => {
            this.kota = data.rows;
          });
          this.onKotaChange(this.currentUser.kota_id);
      }
    },
    loadDataPekerjaan() {
      pekerjaanService.loadAll().then((data) => {
        this.pekerjaan = data;
      });
    },
    loadDataHobi() {
      hobiService.loadAll().then((data) => {
        this.hobi = data;
      });
    },
    resetFilter() {
      this.search.no_kta = "";
      this.search.nama = "";
      this.search.email = "";
      this.search.no_identitas = "";
      this.search.provinsi_id = "";
      this.search.kota_id = "";
      this.search.kecamatan_id = "";
      this.search.kelurahan_id = "";
      this.search.status = "";
      this.search.createdAt = "";
      this.search.jenis_atribut = "";
      this.search.pekerjaan_id = "";
      this.search.hobi_id = "";
      this.searchData();
    },
    onProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((data) => {
        this.kota = data.rows;
      });
    },
    onKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((data) => {
        this.kecamatan = data.rows;
      });
    },
    onKecamatanChange(kecamatan_id) {
      kelurahanService.getAll("", "", "", "", "", kecamatan_id).then((data) => {
        this.kelurahan = data.rows;
      });
    },
    sort_by(sort) {
      if (sort === this.sort) {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sort = sort;
      this.searchData();
    },

    // Pagination methods
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.searchData();
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.searchData();
    },

    // Confirmation methods
    approveAnggota(anggota_id) {
      this.isApproving = true;
      anggotaService
        .approve(anggota_id)
        .then(() => {
          this.$toast.success(`Anggota berhasil disetujui`);
          this.isApproving = false;
          this.loadData(this.limit, this.search, this.currentPage);
        })
        .catch((error) => {
          this.isApproving = false;
          this.$toast.error(error.message);
        });
    },
    rejectAnggota(anggota_id) {
      this.isApproving = true;
      anggotaService
        .reject(anggota_id)
        .then(() => {
          this.$toast.success(`Anggota berhasil ditolak`);
          this.isApproving = false;
          this.loadData(this.limit, this.search, this.currentPage);
        })
        .catch((error) => {
          this.isApproving = false;
          this.$toast.error(error.message);
        });
    },
    confirmPayment(anggota_id) {
      this.isApproving = true;
      anggotaService
        .confirmPayment(anggota_id)
        .then(() => {
          this.$toast.success(`Pembayaran berhasil dikonfirmasi`);
          this.isApproving = false;
          this.loadData(this.limit, this.search, this.currentPage);
        })
        .catch((error) => {
          this.isApproving = false;
          this.$toast.error(error.message);
        });
    },
    confirmProduction(anggota_id) {
      this.isApproving = true;
      anggotaService
        .confirmProduction(anggota_id)
        .then(() => {
          this.$toast.success(`Pencetakan berhasil dikonfirmasi`);
          this.isApproving = false;
          this.loadData(this.limit, this.search, this.currentPage);
        })
        .catch((error) => {
          this.isApproving = false;
          this.$toast.error(error.message);
        });
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    confirmDelete(id) {
      this.showDeleteModal = true;
      this.selectedAnggotaId = id;
    },
    destroyAnggota(id) {
      anggotaService
        .destroy(id)
        .then(() => {
          this.$toast.success(`Anggota berhasil dihapus`);
          this.showDeleteModal = false;
          this.selectedAnggotaId = 0;
          this.searchData();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    showQrCode(qr_code) {
      let routeData = this.$router.resolve({
        name: "show-qr-code",
        query: { id: qr_code },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.loadingData = true;
    this.searchData();
    this.loadDataLokasi();
    this.loadDataPekerjaan();
    this.loadDataHobi();
  },
};
</script>

<style scoped>
.anggota {
  padding: 3rem 1.5rem;
}
</style>
